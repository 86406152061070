import React from "react";
// import PropTypes from "prop-types";

function Footer() {
  return <footer></footer>;
}

Footer.propTypes = {};

export default Footer;
